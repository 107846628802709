import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupercollapseService {

  // if true, is hidden
  public supercollapsers: [String, boolean][] =
    [
      ["hospitals", true],
      ["practices", true],
      ["account-team", true],
      ["recent-activity", true],
      ["required-actions", true],
      ["department-meetings", true],
      ["challenges", true],
      ["opportunities", true],
      ["objectives", true]
    ]

  constructor() { 
    if (localStorage.getItem("kk-acc-supercollapse") !== null) {
      this.supercollapsers = JSON.parse(localStorage.getItem("kk-acc-supercollapse"));
    }
  }

  public getStatus(identifier : string) : boolean {
    return this.supercollapsers.filter(element => element[0] == identifier)[0][1]
  }

  public switchStatus(identifier : string) : void {
    this.supercollapsers.filter(element => element[0] == identifier)[0][1] = !this.supercollapsers.filter(element => element[0] == identifier)[0][1];
    localStorage.setItem("kk-acc-supercollapse", JSON.stringify(this.supercollapsers));
  }
}
