<div id="fixed-title">
  <div id="fixed-title-location">
    <div><b>NYU LANGONE</b> ACCOUNT TACTICAL PLAN</div>
    <div class="responsive-button" style="background-image: url(assets/home.svg);" routerLink="/dashboard"></div>
  </div>
  <div id="fixed-title-welcome">
    <div>
      <div>Good morning <b>Christopher Stump</b>!</div>
    </div>
    <div id="fixed-title-welcome-region">
      <div>EAST REGION</div>
    </div>
  </div>
</div>
<div id="fixed-menu-container">
  <div id="fixed-menu">
    <div></div>
    <div routerLink="/account">
      Account Dashboard
    </div>
    <div routerLink="/account-profile">
      Account Profile
    </div>
    <div routerLink="/key-account-contacts">
      Key Account Contacts
    </div>
    <div class="active">
      Account Tactical Plan
    </div>
    <div></div>
  </div>
</div>
<div id="container">
  <h2><b>NYU Langone</b> Account Tactical Plan</h2>
  <h4><img src="/assets/hospital.svg" /> HOSPITAL</h4>
  <div id="strategic-core">
    <div>ACCOUNT TACTICAL PLAN</div>
    <div style="margin-bottom: 15px;">
      <input type="text" value="" (keyup)="this.demoCreateSnapshot()" [(ngModel)]="this.strategicSitePlan" />
    </div>
  </div>
  <div id="brand-goals">
    <div>
      <div><b>BRAND GOALS</b> (SELECT ONE)</div>
      <div>
        <select [(ngModel)]="this.brandGoals" (change)="this.demoCreateSnapshot()">
          <option>BRAND GOAL 1</option>
          <option>BRAND GOAL 2</option>
          <option>BRAND GOAL 3</option>
        </select>
      </div>
    </div>
  </div>
  <div class="into-columns">
    <div class="column">
      <h3 class="supercollapsible" [class.supercollapsed]="this.supercollapse.getStatus('challenges')"
        (click)="this.supercollapse.switchStatus('challenges')">
        Challenges<button (click)="this.demoAddNewElement('challenges')">
          + ADD NEW CHALLENGE
        </button>
      </h3>
      <div class="universal-editable-list" [class.supercollapsed]="this.supercollapse.getStatus('challenges')">
        <div class="element" *ngFor="
            let challenge of this.challengesOpportunities.challenges;
            let i = index
          ">
          <div><img src="/assets/assignment.svg" /></div>
          <div>
            <input type="text" placeholder="Please enter challenge" value="{{ challenge.value }}" (keyup)="
                this.demoUpdateElement(
                  this.challengesOpportunities.challenges,
                  $event,
                  challenge.index
                )
              " />
          </div>
          <div>
            <img src="/assets/delete.svg" (click)="
                this.demoRemoveElement(
                  this.challengesOpportunities.challenges,
                  'challenges',
                  challenge.index
                )
              " />
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <h3 class="supercollapsible" [class.supercollapsed]="this.supercollapse.getStatus('opportunities')"
        (click)="this.supercollapse.switchStatus('opportunities')">
        Opportunities<button (click)="this.demoAddNewElement('opportunities')">
          + ADD NEW OPPORTUNITY
        </button>
      </h3>
      <div class="universal-editable-list" [class.supercollapsed]="this.supercollapse.getStatus('opportunities')">
        <div class="element" *ngFor="
            let opportunity of this.challengesOpportunities.opportunities;
            let i = index
          ">
          <div><img src="/assets/assignment.svg" /></div>
          <div>
            <input type="text" placeholder="Please enter opportunity" value="{{ opportunity.value }}" (keyup)="
                this.demoUpdateElement(
                  this.challengesOpportunities.opportunities,
                  $event,
                  opportunity.index
                )
              " />
          </div>
          <div>
            <img src="/assets/delete.svg" (click)="
                this.demoRemoveElement(
                  this.challengesOpportunities.challenges,
                  'opportunities',
                  opportunity.index
                )
              " />
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3 class="supercollapsible" [class.supercollapsed]="this.supercollapse.getStatus('objectives')"
    (click)="this.supercollapse.switchStatus('objectives')">OBJECTIVES<button (click)="this.demoAddNewObjective()">+ ADD
      NEW OBJECTIVE</button></h3>
  <div id="objectives" [class.supercollapsed]="this.supercollapse.getStatus('objectives')">
    <div class="objective" [class.expanded]="objective.expanded"
      *ngFor="let objective of this.objectives; let i = index">
      <div class="core" (click)="expandCollapseObjectives(objective.index)">
        <div><img src="/assets/objective.svg" /></div>
        <div>
          Objective {{ i + 1 }}{{this.objectiveTitleShortcut(objective.objective)}}<span>{{ objective.actions.length }}
            action items</span>
        </div>
        <div>
          <img class="info help" src="/assets/help.svg" /><img class="info collapse" src="/assets/collapse.svg" />
        </div>
      </div>
      <div class="details">
        <div></div>
        <div>
          <div class="objective-full">
            <div>
              <div>
                <b>OBJECTIVE</b>&nbsp;<i>(Measurable goal - the “What”)</i>
              </div>
              <textarea (keyup)="this.demoCreateSnapshot()" [(ngModel)]="objective.objective"></textarea>
            </div>
            <div>
              <div>
                <b>STRATEGY</b>&nbsp;<i>(Measurable goal - the “How”)</i>
              </div>
              <textarea (keyup)="this.demoCreateSnapshot()" [(ngModel)]="objective.strategy"></textarea>
            </div>
          </div>
          <div class="objective-actions">
            <div>
              <b>ACTION ITEM / TACTIC</b>&nbsp;<i>(The steps to take)</i>
            </div>
            <div class="actions-table">
              <div>
                <div>ID</div>
                <div>ITEM</div>
                <div>TIMING</div>
                <div>OWNER</div>
              </div>
              <div *ngFor="let action of objective.actions; let i = index">
                <div>{{i + 1}}</div>
                <div><input (keyup)="this.demoCreateSnapshot()" type="text" [(ngModel)]="action.item" /></div>
                <div><input (keyup)="this.demoCreateSnapshot()" type="text" [(ngModel)]="action.timing" /></div>
                <div><input (keyup)="this.demoCreateSnapshot()" type="text" [(ngModel)]="action.owner" /></div>
              </div>
            </div>
          </div>
          <div class="objective-controls">
            <button style="margin-right: 10px;" (click)="this.demoRemoveObjective(objective.index)">REMOVE THIS
              OBJECTIVE</button><button (click)="this.demo.notAvailable()">+ ADD NEW ACTION</button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</div>