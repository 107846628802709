<div id="fixed-title">
    <div id="fixed-title-location">
        <div><b>NYU LANGONE</b> KEY ACCOUNT CONTACTS</div>
        <div class="responsive-button" style="background-image: url(assets/home.svg);" routerLink="/dashboard"></div>
    </div>
    <div id="fixed-title-welcome">
        <div>
            <div>Good morning <b>Christopher Stump</b>!</div>
        </div>
        <div id="fixed-title-welcome-region">
            <div>EAST REGION</div>
        </div>
    </div>
</div>
<div id="fixed-menu-container">
    <div id="fixed-menu">
        <div></div>
        <div routerLink="/account">
            Account Dashboard
        </div>
        <div routerLink="/account-profile">
            Account Profile
        </div>
        <div class="active">
            Key Account Contacts
        </div>
        <div routerLink="/tactical-plan">
            Account Tactical Plan
        </div>
        <div></div>
    </div>
</div>
<div id="container">
    <h2><b>NYU Langone</b> Key Account Contacts</h2>
    <h4><img src="/assets/hospital.svg" /> HOSPITAL</h4>
    <h3>Key Account Contacts <button (click)="this.demo.notAvailable()">+ ADD NEW CONTACT</button></h3>
    <div id="key-account-contacts">
        <div class="contact" *ngFor="let contact of this.contacts" [class.expanded]="contact.expanded">
            <div (click)="this.switchActive(contact.name)">
                <div>
                    <img src="/assets/account.svg" />
                </div>
                <div>
                    {{contact.name}}
                    <span>{{contact.title}}</span>
                </div>
                <div>
                    <img class="help" src="assets/help.svg" />
                    <img class="collapse" src="assets/collapse.svg" />
                </div>
            </div>
            <div [class.expanded-row]="contact.expanded">
                <div></div>
                <div>
                    <ul>
                        <li>{{contact.email}}</li>
                        <li>{{contact.phone}}</li>
                    </ul>
                    <div class="buttons-row">
                        <button (click)="this.demo.notAvailable()">EDIT</button>
                        <button
                            (click)="this.displayingInteractions = true; this.activeInteractions = contact.name">INTERACTIONS</button>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</div>
<div id="interactions-container" *ngIf="this.displayingInteractions">
    <div>
        <h3>INTERACTIONS WITH {{this.activeInteractions}}</h3>
        <div class="interactions">
            <div>
                <div>
                    <span>22th May 2020</span><br>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sed nulla nec massa varius
                    finibus.
                </div>
                <div>
                    <img (click)="this.demo.notAvailable()" src="/assets/delete.svg" />
                </div>
            </div>
            <div>
                <div>
                    <span>20th May 2020</span><br>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sed nulla nec massa varius
                    finibus.
                </div>
                <div>
                    <img (click)="this.demo.notAvailable()" src="/assets/delete.svg" />
                </div>
            </div>
            <div>
                <div>
                    <span>5th May 2020</span><br>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sed nulla nec massa varius
                    finibus.
                </div>
                <div>
                    <img (click)="this.demo.notAvailable()" src="/assets/delete.svg" />
                </div>
            </div>
        </div>
        <div class="buttons-row">
            <button (click)="this.displayingInteractions = false">CLOSE</button>
            <button (click)="this.demo.notAvailable()">+ ADD NEW INTERACITON</button>
        </div>
    </div>
</div>