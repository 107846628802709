import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AccountDashboardPageComponent } from './components/account-dashboard-page/account-dashboard-page.component';
import { AccountDetailsPageComponent } from './components/account-details-page/account-details-page.component';
import { RequiredActionsPageComponent } from './components/required-actions-page/required-actions-page.component';
import { KkAccountProfilePageComponent } from './components/kk-account-profile-page/kk-account-profile-page.component';
import { KkAccountTacticalPlanPageComponent } from './components/kk-account-tactical-plan-page/kk-account-tactical-plan-page.component';
import { KkKeyAccountContactsComponent } from './components/kk-key-account-contacts/kk-key-account-contacts.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AccountDashboardPageComponent,
    AccountDetailsPageComponent,
    RequiredActionsPageComponent,
    KkAccountProfilePageComponent,
    KkAccountTacticalPlanPageComponent,
    KkKeyAccountContactsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
