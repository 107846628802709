<div id="fixed-title">
  <div id="fixed-title-location">
    <div><b>ACCOUNT DASHBOARD</b></div>
    <div class="responsive-button" style="background-image: url(assets/reset.svg); right: 90px;"
      (click)="this.demo.reset()"></div>
    <div class="responsive-button" style="background-image: url(assets/logout.svg);" routerLink="/login"></div>
  </div>
  <div id="fixed-title-welcome">
    <div>
      <div>Good morning <b>Christopher Stump</b>!</div>
    </div>
    <div id="fixed-title-welcome-region">
      <div>EAST REGION</div>
    </div>
  </div>
</div>
<div id="container" class="no-menu">
  <h2>Account Dashboard</h2>
  <p>Select an account to review or update.</p>
  <div class="accounts-selector">
    <h3 class="supercollapsible" [class.supercollapsed]="this.supercollapse.getStatus('hospitals')"
      (click)="this.supercollapse.switchStatus('hospitals')"><img src="/assets/hospital.svg" /> Hospitals</h3>
    <div class="accounts-box" [class.supercollapsed]="this.supercollapse.getStatus('hospitals')">
      <div routerLink="/account">
        <div>NYU Langone</div>
      </div>
      <div (click)="this.demo.notAvailable()">
        <div>Dana-Farber Cancer Institute</div>
      </div>
      <div (click)="this.demo.notAvailable()">
        <div>Montefiore Medical Center</div>
      </div>
      <div (click)="this.demo.notAvailable()">
        <div>Robert Wood Johnson University Hospital</div>
      </div>
      <div (click)="this.demo.notAvailable()">
        <div>Hackensack University Medical Center</div>
      </div>
      <div class="add-new" (click)="this.accountCreationType = 'Hospital'; this.showAccountCreation = true">
        <div><img src="/assets/add.svg" /></div>
      </div>
    </div>
  </div>
  <div class="accounts-selector">
    <h3 class="supercollapsible" [class.supercollapsed]="this.supercollapse.getStatus('practices')"
      (click)="this.supercollapse.switchStatus('practices')"><img src="/assets/practice.svg" /> Practices</h3>
    <div class="accounts-box" [class.supercollapsed]="this.supercollapse.getStatus('practices')">
      <div (click)="this.demo.notAvailable()">
        <div>City Oncology Center</div>
      </div>
      <div (click)="this.demo.notAvailable()">
        <div>Dr. Ferraro Oncology</div>
      </div>
      <div (click)="this.demo.notAvailable()">
        <div>West Community Oncology</div>
      </div>
      <div class="add-new" (click)="this.accountCreationType = 'PRACTICE'; this.showAccountCreation = true">
        <div><img src="/assets/add.svg" /></div>
      </div>
    </div>
  </div>
</div>
<div id="new-account-creation-container" *ngIf="this.showAccountCreation">
  <div>
    <div>
      <h5>Add new account ({{this.accountCreationType}})</h5>
      <div>
        <div>ACCOUNT NAME</div>
        <div><input type="text" /></div>
      </div>
      <div>
        <div>PRIORITY</div>
        <div>
          <select>
            <option>Priority 1</option>
            <option>Priority 2</option>
            <option>Priority 3</option>
          </select>
        </div>
      </div>
      <div>
        <div>ADDRESS</div>
        <div><input type="text" /></div>
        <div><input type="text" /></div>
      </div>
      <div style="text-align: right; margin-top: 40px; margin-bottom: 0px;">
        <div>
          <button (click)="this.showAccountCreation = false">CANCEL</button>
          <button (click)="this.demo.notAvailable()">+ ADD NEW ACCOUNT</button>
        </div>
      </div>
    </div>
  </div>
</div>