import { Component, OnInit } from "@angular/core";
import {Router} from '@angular/router';

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  public username = null;
  public password = null;

  constructor(
    private router : Router
  ) {}

  ngOnInit() {}

  demoCheckCredentials() {
    if (this.username !== null && this.password !== null) {
      if (
        /*this.username.toLowerCase() == "test" &&
        this.password.toLowerCase() == "test"*/
        true
      ) {
        this.router.navigateByUrl("/dashboard");
      } else {
        alert(
          `Incorrect credentials.\r\n\r\nPlease enter the following credentials to access the demo:\r\n\t• Username: test\r\n\t• Password: test`
        );
      }
    } else {
      alert("Please enter credentials.");
    }
  }
}
