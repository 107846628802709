import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DemoService {

  constructor() { }

  notAvailable() {
    alert("This function is not active yet in demo.");
    event.stopPropagation();
  }

  reset() {
    if(confirm("Do you want to reset the application?")) {
      localStorage.clear();
      location.reload();
    }
  }

}
