import { Component, OnInit } from '@angular/core';
import { DemoService } from 'src/app/services/demo.service';

@Component({
  selector: 'app-kk-key-account-contacts',
  templateUrl: './kk-key-account-contacts.component.html',
  styleUrls: ['./kk-key-account-contacts.component.scss']
})
export class KkKeyAccountContactsComponent implements OnInit {

  public contacts = [
    {
      name: "Dr. Zhang",
      title: "Pediatric Oncology",
      email: "Pds.zhang@Langone.com",
      phone: "555-555-5555",
      expanded: false
    },
    {
      name: "NP Morrison",
      title: "Oncology Patient Care",
      email: "Jd.morrison@Langone.com",
      phone: "555-555-5555",
      expanded: false
    },
    {
      name: "Dr. Richards",
      title: "Oncologist",
      email: "Dr.Richards@langone.com",
      phone: "555-555-5555",
      expanded: false
    }
  ]

  public displayingInteractions : boolean = false;
  public activeInteractions : string = null;

  constructor(
    public demo: DemoService
  ) { }

  ngOnInit(): void {
  }

  public switchActive(name: string): void {
    if (this.contacts.filter(contact => contact.name == name)[0].expanded) {
      this.contacts.forEach((contact) => {
        contact.expanded = false;
      })
    } else {
      this.contacts.forEach((contact) => {
        contact.expanded = false;
      })
      this.contacts.filter(contact => contact.name == name)[0].expanded = true;
    }
  }

}
