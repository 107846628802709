import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AccountDashboardPageComponent } from './components/account-dashboard-page/account-dashboard-page.component';
import { AccountDetailsPageComponent } from './components/account-details-page/account-details-page.component';
import { RequiredActionsPageComponent } from './components/required-actions-page/required-actions-page.component';
import { KkAccountProfilePageComponent } from './components/kk-account-profile-page/kk-account-profile-page.component';
import { KkAccountTacticalPlanPageComponent } from './components/kk-account-tactical-plan-page/kk-account-tactical-plan-page.component';
import { KkKeyAccountContactsComponent } from './components/kk-key-account-contacts/kk-key-account-contacts.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'dashboard',
    component: AccountDashboardPageComponent
  },
  {
    path: 'account',
    component: AccountDetailsPageComponent
  },
  {
    path: 'required-actions',
    component: RequiredActionsPageComponent
  },
  {
    path: 'account-profile',
    component: KkAccountProfilePageComponent
  },
  {
    path: 'key-account-contacts',
    component: KkKeyAccountContactsComponent
  },
  {
    path: 'tactical-plan',
    component: KkAccountTacticalPlanPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
